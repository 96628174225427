.custom-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header */
.custom-table thead th {
  padding: 12px;
  background-color: #f2f2f2;
}
/* Image Cell */
.category-table .image-cell,
.brand-table .image-cell {
  width: 25%;
}
.category-table .image-cell img,
.brand-table .image-cell img {
  display: block;
  margin: 0 auto;
}

/* Tên danh mục Cell */
.category-table .cateName-cell,
.brand-table .brandName-cell {
  width: 20%;
  font-weight: 500;
  color: #333;
  cursor: pointer;
}

/* Đường dẫn Cell */
.category-table .cateSlug-cell,
.category-table .description-cell,
.brand-table .brandSlug-cell,
.brand-table .description-cell {
  width: 20%;
  color: #666;
  cursor: pointer;
}

/* Checkbox Cell */
.category-table .checkbox-cell,
.brand-table .checkbox-cell {
  width: 5%;
  text-align: center;
}

/* Delete Cell */
.category-table .delete-cell,
.brand-table .delete-cell {
  width: 10%;
  text-align: center;
}
