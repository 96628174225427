.control-container .item {
  margin-left: 0.8rem;
}
.product_dialog {
  > div > div {
    max-width: 70vw;
  }
}

@media (min-width: 1024px) {
  .product_dialog {
    > div > div {
      max-width: 950px;
    }
  }
}
