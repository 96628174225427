.custom-table {
  width: 100%;
}
.custom-table td,
.custom-table th {
  text-align: center;
}

.checkbox-cell {
  width: 5%;
}

.image-cell {
  width: 35%;
  cursor: pointer;
}
.image-cell img {
  object-fit: contain;
}

.description-cell,
.linkTo-cell {
  cursor: pointer;
  width: 20%;
}

.activity-cell {
  width: 10%;
}
.delete-cell {
  width: 10%;
}
.activity-cell button {
  width: 2rem;
}
.control_container > *:not(:last-child) {
  margin-right: 10px;
}
.title_container:hover {
  color: darkred;
}
